import React from 'react'
// import { Link } from 'react-router-dom'

const Home = () => {

  return (
    <section id="top">
      <h2>
        Hi! My name is 
        <br />
        <br />
        <span>Victor Yoke Loong Liew, </span>
        <br />
        <br/>
        I am a full stack Software Engineer.
      </h2>
    </section>
  )

}

export default Home